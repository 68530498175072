import Image from "next/image";
import React from "react";

const LoginIllustration: React.FC = () => (
  <div className="relative -mx-5 sm:mx-0 max-w-none w-screen sm:w-full h-[20.5rem] sm:min-h-[20.5rem] sm:h-full sm:max-h-[29.625rem] text-center sm:flex-1">
    <Image
      priority
      src="/illustrations/login.png"
      layout="fill"
      className="object-cover sm:object-contain h-full w-full"
    />
  </div>
);

export default LoginIllustration;
