import { Button, LoginDetails } from "@fyvedev/fe-storybook";
import { loginTitle, managerMessage } from "lib/const/login";
import React from "react";
import LoginButtonWrapper from "./LoginButtonWrapper";

interface LoginManagerProps {
  onProceed: () => void;
}

const LoginManager: React.FC<LoginManagerProps> = ({ onProceed }) => {
  return (
    <>
      <LoginDetails
        className="sm:w-[38rem] text-center"
        title={loginTitle}
        message={managerMessage}
      />
      <LoginButtonWrapper>
        <Button
          className="w-full"
          onClick={() => {
            onProceed();
          }}
        >
          Main page
        </Button>{" "}
      </LoginButtonWrapper>
    </>
  );
};

export default LoginManager;
