import { Button, LoginDetails } from "@fyvedev/fe-storybook";
import { loginTitle } from "lib/const/login";
import Routes from "lib/const/routes";
import Link from "next/link";
import React from "react";
import LoginButtonWrapper from "./LoginButtonWrapper";

const LoginLease: React.FC = () => {
  return (
    <>
      <LoginDetails
        title={loginTitle}
        className="sm:w-[38rem] text-center"
        message={
          <>
            {
              "Not found. To find out your mobile phone number or email are\nspecified within your current lease record, please contact us at\n"
            }
            <a className="font-normal" href="mailto:support@fyve.com">
              support@fyve.com
            </a>{" "}
            or{" "}
            <a className="font-normal" href="tel:+18445483983">
              +1 (844) 548-3983
            </a>
          </>
        }
      />
      <LoginButtonWrapper>
        <Link passHref href={Routes.authSignIn}>
          <Button as="a" className="w-full">
            Ok
          </Button>
        </Link>
      </LoginButtonWrapper>
    </>
  );
};

export default LoginLease;
