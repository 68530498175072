import { Button, InputHeading, LoginDetails } from "@fyvedev/fe-storybook";
import { loginInputPlaceholder, loginTitle } from "lib/const/login";
import React from "react";
import LoginButtonWrapper from "../LoginButtonWrapper";
import { useSubmit } from "./hooks";

const SignInForm: React.FC = () => {
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    inputError,
    inputValid,
    isSubmitted,
  } = useSubmit();

  return (
    <>
      <LoginDetails
        title={loginTitle}
        message={
          <>
            Use the mobile phone number or email are specified{"\n"}within your
            lease record to get one&#8209;time password code.
          </>
        }
        className="sm:w-[38rem] text-center"
      />
      <InputHeading
        plain
        variant="textarea"
        name="phoneNumberOrEmail"
        className="mt-9"
        placeholder={loginInputPlaceholder}
        error={inputError}
        onBlur={(e) => {
          handleBlur(e.target.value);
        }}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
      <LoginButtonWrapper>
        <Button
          id="signInNext"
          className="w-full"
          disabled={!inputValid || isSubmitted}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </LoginButtonWrapper>
    </>
  );
};

export default SignInForm;
