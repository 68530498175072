import React from "react";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useRouter } from "next/router";
import { useAuthControllerSendOtpRequest } from "@fyvedev/client";
import Routes from "lib/const/routes";

const EMAIL_REGEXP =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

const isEmail = (value: string) => value.match(EMAIL_REGEXP) !== null;
const isPhoneNumber = (value: string) =>
  isValidPhoneNumber(value.replace(/\W/g, ""), "US");

export const useSubmit = () => {
  const [inputValue, setInputValue] = React.useState("");
  const [inputValid, setInputValid] = React.useState(false);
  const [inputError, setInputError] = React.useState<undefined | string>();

  const handleChange = React.useCallback((value: string) => {
    setInputError(undefined);
    setInputValue(value);
    setInputValid(isEmail(value) || isPhoneNumber(value));
  }, []);

  const handleBlur = React.useCallback((value: string) => {
    if (isEmail(value) || isPhoneNumber(value)) {
      return;
    }

    setInputError("Enter correct phone number or email");
  }, []);

  const router = useRouter();

  const onSuccess = React.useCallback(
    async (phoneNumberOrEmail: string) =>
      router.push({
        pathname: Routes.authOtp,
        query: {
          phoneNumberOrEmail,
          role: "tenant",
          ...router.query,
        },
      }),
    [router]
  );

  const mutateOTP = useAuthControllerSendOtpRequest({
    mutation: {
      onSuccess: async (_, { data }) => onSuccess(data.phoneNumberOrEmail),
      onError: async (e, { data }) => {
        // Too many requests -> Ignore and go to OTP page
        if (e.response?.status === 429) {
          return onSuccess(data.phoneNumberOrEmail);
        }

        return router.push(Routes.authLease);
      },
    },
  });

  const onSignInClick = React.useCallback(
    (value: string) => {
      const phoneNumberOrEmail = value.replace("+1", "");
      mutateOTP.mutate({
        data: { phoneNumberOrEmail, role: "tenant" },
      });
    },
    [mutateOTP]
  );

  const handleSubmit = React.useCallback(() => {
    if (isPhoneNumber(inputValue)) {
      onSignInClick(
        parsePhoneNumber(inputValue.replace(/\W/g, ""), "US")
          .formatInternational()
          .replace(/\s/g, "")
      );
    } else {
      onSignInClick(inputValue.trim());
    }
  }, [inputValue, onSignInClick]);

  return {
    inputValid,
    inputError,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitted: mutateOTP.isLoading,
  };
};
