const Routes = {
  // Auth
  auth: "/auth",
  authLease: "/auth/lease",
  authManager: "/auth/manager",
  authOtp: "/auth/otp",
  authSignIn: "/auth/signIn",
  // App
  base: "/app",
  // Financials
  financials: "/app/financials",
  financialDetails: "/app/financials/details",
  financialLedgerDetails: "/app/financials/details/ledger-details",
  // Maintenance
  maintenance: "/app/maintenance",
  newWorkRequest: "/app/maintenance/edit-work-request",
  // Profile
  profile: "/app/profile",
  moveOutNotice: "/app/profile/move-out-notice",
  moveOutNoticeDetails: "/app/profile/move-out-notice/move-out-notice-details",
  moveOutNoticeSent: "/app/profile/move-out-notice/move-out-notice-sent",
  // Other
  addNewCard: "/app/add-new-card",
  overview: "/app/overview",
  payment: "/app/payment",
};

export default Routes;
