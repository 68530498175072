import {
  authControllerSendOtpRequest,
  SendOtpRequest,
  useAuthControllerSignIn,
} from "@fyvedev/client";
import {
  AlertStatus,
  Button,
  InputHeading,
  Loader,
  LoginDetails,
  Notice,
  NoticePositionX,
  Portal,
} from "@fyvedev/fe-storybook";
import { loginTitle } from "lib/const/login";
import Routes from "lib/const/routes";
import phoneNumberMask from "lib/masks/phone-number";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import React from "react";
import { useTimer } from "react-timer-hook";
import LoginButtonWrapper from "./LoginButtonWrapper";

const getBaseParamsFromQuery = (
  query: Partial<SendOtpRequest>
): SendOtpRequest => {
  return {
    phoneNumberOrEmail: String(query.phoneNumberOrEmail ?? ""),
    role: String(query.role ?? "tenant"),
  };
};

const LoginMessage: React.FC<{
  isPhoneNumberOrEmailString: boolean;
  isEmail: boolean;
  phoneNumberOrEmail?: string;
}> = ({ isEmail, isPhoneNumberOrEmailString, phoneNumberOrEmail }) => {
  if (!isPhoneNumberOrEmailString) {
    return null;
  }

  if (isEmail) {
    return (
      <>
        Please enter the code we&apos;ve just sent{" "}
        <br className="hidden sm:block" />
        to {phoneNumberOrEmail}.
      </>
    );
  }

  return (
    <>
      We&apos;ve texted your phone {phoneNumberMask(phoneNumberOrEmail)}. Please
      enter <br className="hidden sm:block" />
      the code to sign in.
    </>
  );
};

const LoginOTP: React.FC = () => {
  const router = useRouter();

  const signInMutation = useAuthControllerSignIn({
    mutation: {
      onSuccess: (res) => {
        void signIn("credentials", {
          accessToken: res.accessToken,
          callbackUrl: String(router.query.callbackUrl ?? Routes.base),
        });
      },
      onError: async () => {
        setError(true);
        setValue(["", "", "", ""]);
        setActiveIndex(0);
      },
    },
  });

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [value, setValue] = React.useState(["", "", "", ""]);
  const [error, setError] = React.useState(false);
  const time = new Date();
  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(time.setSeconds(time.getSeconds() + 30)),
    autoStart: true,
  });

  const sendOTP = React.useCallback(
    (newActiveIndex: number, newValue: string[]) => {
      if (newActiveIndex === 4) {
        signInMutation.mutate({
          data: {
            otp: newValue.join(""),
            ...getBaseParamsFromQuery(router.query),
          },
        });
      }
    },
    [router.query, signInMutation]
  );

  const handleValueChange: (value: string, index: number) => void = (
    inputValue,
    index
  ) => {
    if (inputValue.length > 0) {
      setError(false);

      const valueCopy = Array.from(value);
      valueCopy[index] = inputValue;
      setValue(valueCopy);
      setActiveIndex(index + 1);
      sendOTP(index + 1, valueCopy);
    }
  };

  const handlePaste = (value: string) => {
    setValue(value.split("").slice(0, 4));
    setActiveIndex(4);
    sendOTP(4, value.split(""));
  };

  const handleBackspace = (index: number) => {
    const valueCopy = Array.from(value);
    if (valueCopy[index] === "") {
      setActiveIndex(Math.max(0, index - 1));
    } else {
      valueCopy[index] = "";
      setValue(valueCopy);
    }
  };

  const phoneNumberOrEmail = router.query.phoneNumberOrEmail as
    | string
    | undefined;

  const isPhoneNumberOrEmailString = typeof phoneNumberOrEmail === "string";
  const isEmail = isPhoneNumberOrEmailString
    ? /@/g.test(phoneNumberOrEmail)
    : false;

  const actionMessage = isEmail ? "Edit my email." : "Edit my phone number.";

  return (
    <>
      <LoginDetails
        title={loginTitle}
        message={
          <LoginMessage
            isPhoneNumberOrEmailString={isPhoneNumberOrEmailString}
            isEmail={isEmail}
            phoneNumberOrEmail={phoneNumberOrEmail}
          />
        }
        actionMessage={actionMessage}
        className="sm:w-[38rem] text-center"
        onActionClick={async () => router.push(Routes.authSignIn)}
      />
      <div className="mt-6 sm:mt-10 flex gap-2.5">
        {signInMutation.isLoading || signInMutation.isSuccess ? (
          <Loader type="dots" className="w-16 h-20" />
        ) : (
          Array(4)
            .fill(0)
            .map((_, index) => (
              <ManagedInput
                key={String(index.toString()) + value[index]}
                index={index}
                activeIndex={activeIndex}
                disabled={signInMutation.isLoading || signInMutation.isSuccess}
                value={value[index]}
                onChange={(value) => {
                  handleValueChange(value, index);
                }}
                onPaste={handlePaste}
                onBackspace={() => {
                  handleBackspace(index);
                }}
              />
            ))
        )}
      </div>
      <LoginButtonWrapper>
        <Button
          disabled={isRunning}
          id="otpReset"
          variant="text"
          className="w-full"
          onClick={() => {
            const time = new Date();
            restart(new Date(time.setSeconds(time.getSeconds() + 30)), true);
            void authControllerSendOtpRequest(
              getBaseParamsFromQuery(router.query)
            );
          }}
        >
          Send the code again{" "}
          {seconds !== 0 &&
            "0:".concat(seconds > 9 ? String(seconds) : `0${seconds}`)}
        </Button>
      </LoginButtonWrapper>

      <Portal isShow id="notice-top-right">
        {error && (
          <Notice
            status={AlertStatus.ERROR}
            positionX={NoticePositionX.RIGHT}
            alertHeading="Authorization error"
            size="m"
            hide={() => {
              setError(false);
            }}
          >
            Code is wrong. Please try again
          </Notice>
        )}
      </Portal>
    </>
  );
};

interface ManagedInputProps {
  index: number;
  activeIndex: number;
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  onPaste: (value: string) => void;
  onBackspace: () => void;
}

const ManagedInput: React.FC<ManagedInputProps> = ({
  index,
  activeIndex,
  value,
  disabled,
  onChange,
  onPaste,
  onBackspace,
}) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (index === activeIndex) {
      ref.current?.focus();
    }
  }, [index, activeIndex, ref]);

  return (
    <InputHeading
      ref={ref}
      autoFocus
      name={`otp[${index}]`}
      disabled={disabled}
      type="number"
      className="w-[3.75rem] h-[3.75rem] sm:w-20 sm:h-20 border-pitchy-black-300"
      value={value}
      onChange={(e) => {
        e.preventDefault();
        if (e.target.value.length > 1 && ref.current) {
          ref.current.value = e.target.value[1];
        }

        onChange(e.target.value);
      }}
      onKeyUp={(e) => {
        if (e.key === "Backspace") {
          onBackspace();
        }
      }}
      onWheel={() => {
        ref.current?.blur();
      }}
      onPaste={(e) => {
        e.preventDefault();
        const clipboardValue = e.clipboardData.getData("Text").trim();
        if (/[0-9]{4}/gi.test(clipboardValue)) {
          onPaste(clipboardValue);
        }
      }}
    />
  );
};

export default LoginOTP;
