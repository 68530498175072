import Head from "next/head";
import React from "react";

interface IHeadTitle {
  page?: string;
  children?: React.ReactNode;
}

const HeadTitle: React.FC<IHeadTitle> = ({ page, children }) => {
  return (
    <Head>
      <title>{page ? `${page} | ` : ""}Tenant Platform</title>
      {children}
    </Head>
  );
};

export default HeadTitle;
