import React from "react";

interface LoginButtonWrapperProps extends React.PropsWithChildren {}

const LoginButtonWrapper: React.FC<LoginButtonWrapperProps> = ({
  children,
}) => {
  return (
    <div className="flex-1 sm:flex-initial w-full sm:w-52 mt-9 flex items-end">
      {children}
    </div>
  );
};

export default LoginButtonWrapper;
