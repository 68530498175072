const MAX_PHONE_LENGTH = 10;

/**
 * Phone number mask
 * @param {string} value without +1, only numbers
 * @returns {string} mask as (###) ###-####
 */
export default function phoneNumberMask(value?: string): string {
  if (!value) {
    return "";
  }

  let newValue = value.replace(/\D/g, "");

  if (newValue.length > MAX_PHONE_LENGTH) {
    newValue = newValue.substring(
      newValue.length - MAX_PHONE_LENGTH,
      newValue.length
    );
  } else if (newValue.length === 0) {
    return "";
  }

  if (newValue.length <= 3) {
    return `(${newValue}`;
  }

  if (newValue.length <= 6) {
    return `(${newValue.slice(0, 3)}) ${newValue.slice(3)}`;
  }

  return `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(
    6,
    10
  )}`;
}
