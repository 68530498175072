/**
 * This is base url of API
 */
export const BASE_URL: string = process.env.NEXT_PUBLIC_API_ENDPOINT || "";

export const IS_PAYMENT_ACTION = Boolean(Number(process.env.PAYMENT_ACTION));

export enum HeaderNav {
  Overview = "Overview",
  Financials = "Financials",
  FinancialsDetails = "Details | Financials",
  FinancialsLedgerDetails = "Ledger Details | Details | Financials",
  Maintenance = "Maintenance",
  AddNewCard = "Add new card",
  AddNewWorkOrderRequest = "Add new work order request | Overview",
  Payment = "Payment",
  Profile = "Profile",
  GiveMoveOutNotice = "Give move out notice | Profile",
  MoveOutNoticeDetails = "Move out notice details | Profile",
  MoveOutNoticeWasSent = "Move out notice was sent",
  SignIn = "Sign In",
  FYVE = "FYVE",
}

export const HEADER_NAV_LIST = [
  HeaderNav.Overview,
  HeaderNav.Financials,
  HeaderNav.Maintenance,
];

export const HEADER_NAV_LIST_MOBILE = [...HEADER_NAV_LIST, HeaderNav.Profile];
